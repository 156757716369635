<template>
    <div>
        <div class="p-d-flex p-jc-between">
            <p class="profile-title">Question Library</p>
            <!-- <div >
                <Button label="Cancel" class="p-button p-component p-button-outlined button" @click="cancelAnswerBank" />
                <Button label="Save" class="p-button p-component button" @click="saveAnswerBank" />
            </div> -->
        </div>

        <div class="card flex justify-center">
            <TreeSelect
                v-model="activeDirectory"
                :options="directoryTreeData"
                placeholder="Select the Answer Bank to review"
                style="width: 50rem"
                inputStyle="{fontSize: '1.2rem'}"
                fluid="true"
                selectionMode="single"
                @change="directoryChanged"
            />
            <span class="directoryTitle">{{ activeDirectoryTitle }}</span>
        </div>

        <div class="p-d-flex">
            <div class="card left-container p-mx-3 p-my-2">
                <div class="p-d-flex p-jc-between">
                    <p class="title">Categories</p>
                    <Button label="Save" class="p-button p-component button" @click="saveCategoriesOrder" />
                </div>
                <DataTable
                    :value="templates"
                    @rowReorder="onCategoryRowReorder"
                    v-model:selection="selectedProduct"
                    selectionMode="single"
                    @rowSelect="onRowSelect"
                    dataKey="category_id"
                    tableStyle="min-width: 100px"
                >
                    <Column rowReorder headerStyle="width: 5rem" :reorderableColumn="false" />
                    <Column key="category_id" field="category_name" header="Category Name" />
                    <Column headerStyle="width: 3rem">
                        <template #body="slotProps">
                            <Button
                                icon="pi pi-pencil"
                                class="p-button-outlined"
                                @click="openCategoryEditModal(slotProps.data)"
                            />
                        </template>
                    </Column>
                </DataTable>
            </div>

            <div class="card right-container p-mx-3 p-my-2">
                <div class="p-d-flex p-jc-between">
                    <p class="title">Questions</p>
                    <div>
                        <!-- <Button label="Cancel" class="p-button p-component p-button-outlined button" @click="cancelAnswerBank" /> -->
                        <Button label="Save" class="p-button p-component button" @click="saveQuestionsOrder" />
                    </div>
                </div>
                <DataTable :value="questionSets" @rowReorder="onQuestionRowReorder" dataKey="id" tableStyle="min-width: 100px">
                    <Column rowReorder headerStyle="width: 5rem" />
                    <Column key="id" field="display_name" header="Question" />
                    <Column headerStyle="width: 3rem">
                        <template #body="slotProps">
                            <Button
                                icon="pi pi-pencil"
                                class="p-button-outlined"
                                @click="openQuestionEditModal(slotProps.data)"
                            />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>

        <Dialog v-model:visible="displayCategoryModal" :style="{ width: '50vw' }" header="Edit Category" :modal="true">
            <div class="p-fluid">
                <div class="p-field">
                    <label for="category_name">Category Name</label>
                    <InputText id="category_name" v-model="editingCategory.category_name" />
                </div>
            </div>
            <template #footer>
                <Button label="Cancel" icon="pi pi-times" @click="closeCategoryModal" class="p-button-text" />
                <Button label="Save" icon="pi pi-check" @click="saveCategory" autofocus />
            </template>
        </Dialog>

        <Dialog v-model:visible="displayQuestionModal" :style="{ width: '50vw' }" header="Edit Question" :modal="true">
            <div class="p-fluid">
                <div class="p-field">
                    <label for="field_name">Field Name</label>
                    <InputText id="field_name" v-model="editingQuestion.field_name" />
                </div>
                <div class="p-field">
                    <label for="display_name">Display Name</label>
                    <InputText id="display_name" v-model="editingQuestion.display_name" />
                </div>
                <div class="p-field">
                    <label for="display_name">Question Text</label>
                    <InputText id="display_name" v-model="editingQuestion.question_text" />
                </div>
                <div class="p-grid">
                    <div class="p-col-6">
                        <div class="p-field">
                            <label for="field_type">Field Type</label>
                            <Dropdown
                                id="field_type"
                                v-model="editingQuestion.field_type"
                                :options="fieldTypeOptions"
                                optionLabel="name"
                                optionValue="value"
                                placeholder="Select Field Type"
                                :disabled="true"
                            />
                        </div>
                    </div>
                    <div class="p-col-6">
                        <div class="p-field">
                            <label for="data_type">Data Type</label>
                            <Dropdown
                                id="data_type"
                                v-model="editingQuestion.data_type"
                                :options="dataTypeOptions"
                                optionLabel="name"
                                optionValue="name"
                                placeholder="Select Data Type"
                                :disabled="true"
                            />
                        </div>
                    </div>
                </div>
                <div class="p-field">
                    <label for="placeholder">Placeholder</label>
                    <InputText id="placeholder" v-model="editingQuestion.placeholder" />
                </div>
                <div class="p-field">
                    <label for="description">Description</label>
                    <InputText id="description" v-model="editingQuestion.description" />
                </div>
                <div class="p-field">
                    <label for="dropdown_options">Dropdown Options</label>
                    <Textarea id="dropdown_options" v-model="dropdownOptionsJson" rows="5" @input="updateDropdownOptions" />
                </div>
                <div class="p-field">
                    <label for="error_message">Error Message</label>
                    <InputText id="error_message" v-model="editingQuestion.error_message" />
                </div>
                <div class="flex align-items-center">
                    <Checkbox id="attachment_required" v-model="editingQuestion.attachment_required" :binary="true" />
                    <label for="attachment_required" class="ml-5">Attachment Required</label>
                </div>
            </div>
            <template #footer>
                <Button label="Cancel" icon="pi pi-times" @click="closeQuestionModal" class="p-button-text" />
                <Button label="Save" icon="pi pi-check" @click="saveQuestion" autofocus />
            </template>
        </Dialog>
    </div>
</template>

<script>
import AnswerBankService from '../service/AnswerBankService';
import QuestionBankService from '../service/QuestionBankService';

export default {
    name: 'QuestionBank',
    data() {
        return {
            activeDirectory: null,
            activeDirectoryTitle: null,
            directoryTreeData: null,
            qsid: null,
            templates: [],
            questionSets: [],
            displayCategoryModal: false,
            editingCategory: {},
            displayQuestionModal: false,
            editingQuestion: {},
            dropdownOptionsJson: '',
            dataTypeOptions: [
                { name: 'Short Text' },
                { name: 'Long Text' },
                { name: 'Numeric' },
                { name: 'Single select drop down' },
                { name: 'Multi select drop down' },
                { name: 'Decimal' },
                { name: 'File Upload' },
                { name: 'Object' },
                { name: 'Date' },
            ],
            fieldTypeOptions: [
                { name: 'Short Text', value: 'short_text' },
                { name: 'Number', value: 'number' },
                { name: 'Decimal', value: 'decimal' },
                { name: 'Long Text', value: 'long_text' },
                { name: 'Single Select', value: 'single_select' },
                { name: 'Multi Select', value: 'multi_select' },
                { name: 'Multi Select Search', value: 'multi_select_search' },
                { name: 'Tree Select', value: 'tree_select' },
                { name: 'File Upload', value: 'file_upload' },
                { name: 'Single Select Search', value: 'single_select_search' },
                { name: 'Boolean Select', value: 'boolean_select' },
            ],
        };
    },
    created() {
        // this.questionBankService = new QuestionBankService();
        this.answerBankService = new AnswerBankService();
        this.directoryTreeData = this.answerBankService.getDirectoryTreeData();
        this.questionBankService = new QuestionBankService();
    },
    async mounted() {
        localStorage.removeItem('qsid');
        localStorage.removeItem('categoryId');
        this.updateQsid();
    },
    methods: {
        directoryChanged(event) {
            console.log('Selected value:', event);

            const selectedKey = Object.keys(event)[0];

            const findNestedItem = (items) => {
                for (let item of items) {
                    if (item.key === selectedKey) {
                        return item;
                    }
                    if (item.children) {
                        const found = findNestedItem(item.children);
                        if (found) return found;
                    }
                }
                return null;
            };

            const selectedItem = findNestedItem(this.directoryTreeData);

            localStorage.setItem('qsid', JSON.stringify(selectedItem.data));
            this.updateQsid();

            this.activeDirectoryTitle = selectedKey.replaceAll(':', ' > ');
            this.getTemplateForQuestionSet();
        },

        updateQsid() {
            const storedQsid = localStorage.getItem('qsid');
            this.qsid = storedQsid ? JSON.parse(storedQsid) : null;
        },

        async getTemplateForQuestionSet() {
            let qsid = localStorage.getItem('qsid');
            const response = await this.answerBankService.getTemplateById(qsid);
            console.log('response', response.data);
            localStorage.setItem('template', JSON.stringify(response.data.data));
            this.templates = response.data.data.data;
        },

        onCategoryRowReorder(event) {
            console.log('event', event);
            this.templates = event.value;

            // Update the category_position for each category
            this.templates.forEach((category, index) => {
                category.category_position = index + 1;
            });
        },
        onQuestionRowReorder(event) {
            console.log('event', event);
            this.questionSets = event.value;

            // Update the field_position for each question
            this.questionSets.forEach((question, index) => {
                question.field_position = index + 1;
            });
        },

        onRowSelect(event) {
            console.log('onRowSelectEvent', event);
            console.log('questions', event.data.data);
            localStorage.setItem('categoryId', event.data.category_id);
            this.questionSets = event.data.data;
        },

        openQuestionEditModal(question) {
            this.editingQuestion = { ...question };
            this.dropdownOptionsJson = JSON.stringify(question.dropdown_options, null, 2);
            this.displayQuestionModal = true;
        },
        closeQuestionModal() {
            this.displayQuestionModal = false;
            this.editingQuestion = {};
        },
        saveQuestion() {
            // Implement the logic to save the edited question
            console.log('Saving question:', this.editingQuestion);
            // Update the questionSets array with the edited question
            const index = this.questionSets.findIndex((q) => q.id === this.editingQuestion.id);
            if (index !== -1) {
                this.questionSets[index] = { ...this.editingQuestion };
            }

            this.questionBankService
                .updateQuestion(this.editingQuestion, this.qsid, localStorage.getItem('categoryId'))
                .then((res) => {
                    console.log('res', res);
                });

            this.closeQuestionModal();
        },

        openCategoryEditModal(category) {
            this.editingCategory = { ...category };
            this.displayCategoryModal = true;
        },
        closeCategoryModal() {
            this.displayCategoryModal = false;
            this.editingCategory = {};
        },
        saveCategory() {
            // Implement the logic to save the edited category
            console.log('Saving category:', this.editingCategory);
            // Update the templates array with the edited category
            const index = this.templates.findIndex((c) => c.category_id === this.editingCategory.category_id);
            if (index !== -1) {
                this.templates[index] = { ...this.editingCategory };
            }

            this.questionBankService.updateCategory(this.editingCategory, this.qsid).then((res) => {
                console.log('res', res);
            });

            this.closeCategoryModal();
        },

        saveQuestionsOrder() {
            console.log('questionSets', this.questionSets);

            this.questionBankService
                .saveQuestionsOrder(this.questionSets, this.qsid, localStorage.getItem('categoryId'))
                .then((res) => {
                    console.log('res', res);
                });
        },

        saveCategoriesOrder() {
            console.log('templates', this.templates);
            this.questionBankService.saveCategoriesOrder(this.templates).then((res) => {
                console.log('res', res);
            });
        },

        updateDropdownOptions() {
            try {
                this.editingQuestion.dropdown_options = JSON.parse(this.dropdownOptionsJson);
            } catch (error) {
                // Handle parsing error (e.g., show an error message to the user)
                console.error('Invalid JSON for dropdown options:', error);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.profile-title {
    font-size: 1.75rem;
    font-weight: 500;
    margin-left: 1rem;
}

.directoryTitle {
    font-size: 1.5rem;
    margin-left: 1rem;
}

.left-container {
    width: 40%;
    min-width: 300px;
}
.right-container {
    width: 60%;
    min-width: 400px;
}

.title {
    font-size: 1.25rem;
    font-weight: 500;
}

::v-deep(.p-accordion-header) {
    font-size: large;
}

::v-deep(.p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link),
::v-deep(.p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link):hover {
    background-color: rgb(228, 228, 228);
    font-weight: 500;
    margin-top: 1rem;
}

::v-deep(.p-button) {
    margin: 0 0 1.25rem 1.25rem;
    width: 8rem !important;
    background: #00796b !important;
    border: 2px solid #00796b !important;
}

::v-deep(.p-button.p-button-outlined) {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
}

.field-radiobutton label {
    margin-left: 0.5rem;
}
</style>
